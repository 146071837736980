/* You can add global styles to this file, and also import other style files */


/*!
*/

@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~ngx-toastr/toastr.css";
// @import "https://fonts.googleapis.com/css?family=Maven+Pro&display=swap";
// @import "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;1,400&display=swap";
@import "assets/scss/core/variables";
@import "assets/scss/core/mixins";
@import "assets/scss/core/upload";
@import "~bootstrap/scss/bootstrap";
@import '~ng-wizard/themes/ng_wizard.min.css';
@import '~ng-wizard/themes/ng_wizard_theme_circles.min.css';
@import '~ng-wizard/themes/ng_wizard_theme_dots.min.css';
//@import "assets/scss/core/mixins";
///// login
* {
    font-family: 'Montserrat', sans-serif;
}

p {
    font-weight: 400;
}

.ng-wizard-theme-circles .step-content {
    background-color: #0b0f24 !important;
}

.btnCancelar {
    border-top-right-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
}

.btnAnterior {
    border-top-left-radius: 15px !important;
    border-bottom-left-radius: 15px !important;
    margin-left: auto !important;
}

.ng-wizard-theme-circles>ul.step-anchor {
    background: #0b0f24;
}

.nav-tabs .nav-item:first-child .nav-link {
    border-radius: 50% !important;
}

.nav-tabs .nav-item:last-child .nav-link {
    border-radius: 50% !important;
}

.ng-wizard-theme-circles .ng-wizard-toolbar {
    background-color: #0b0f24 !important;
}

.tab-content {
    border: 0 !important;
    /* border-top: 0; */
}

.ng-wizard-theme-dots>ul.step-anchor {
    position: relative;
    background: #0b0f24;
    border: 0 solid #ccc!important;
    list-style: none;
}

.ng-wizard-theme-dots .step-content {
    padding: 10px 0;
    border: none;
    background-color: #0b0f24;
    text-align: left;
}

.ng-wizard-theme-dots .ng-wizard-toolbar {
    background: #0b0f24;
    border-radius: 0!important;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 0!important;
}

.ng-wizard-theme-dots>ul.step-anchor>li>a:after {
    opacity: 0;
}
// active - #428bca, done - #5cb85c, empty - #f5f5f5
// .ng-wizard-theme-dots>ul.step-anchor>li.active>a:before {
//     background:#428bca;
// }
// .ng-wizard-theme-dots>ul.step-anchor>li.done>a:before {
//     background:#5cb85c;
// }
// .ng-wizard-theme-dots>ul.step-anchor>li:hover>a:before {
//     background:#999;
// }
.ng-wizard-theme-dots>ul.step-anchor:before {
    content: " ";
    position: absolute;
    top: auto;
    bottom: 13px;
    left: 0;
    right: 0;
    margin: auto;
    width: 90%;
    height: 5px;
    background-color: #f5f5f5;
    border-radius: 3px;    
    // z-order: 0;
    z-index: 95
}

.ng-wizard-theme-dots>ul.step-anchor {    
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    position: relative;
    background: #0b0f24;
    border: 0 solid #ccc !important;
    list-style: none;
}

// Theme Circles
.ng-wizard-theme-circles>ul.step-anchor{
    display: flex;
    justify-content: space-between;    
}
.ng-wizard-theme-circles>ul.step-anchor:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 93%;
    height: 5px;
    background-color: #f5f5f5;
    border-radius: 3px;    
    // z-order: 0;
    z-index: 95
}
.ng-wizard-theme-circles>ul.step-anchor>li {
    margin-left: 0;
}
.ng-wizard-theme-circles>ul.step-anchor>li>a {
    transform: scale(0.5);
    font-size: 40px;
    line-height: 20px;
    box-shadow: inset 0 0 0 0 transparent !important;
    font-weight: bold;
}
// .nav-tabs .nav-link:hover
.ng-wizard-theme-circles>ul.step-anchor>li.clickable>a:hover {
    color: #FFFFFF !important;
    background-color: #BBBBBB;
    border-color: transparent;
}
.ng-wizard-theme-circles>ul.step-anchor>li.clickable>a:hover>small {
    color: #BBBBBB !important;
 
}
.ng-wizard-theme-circles>ul.step-anchor>li>a>small {
    position: absolute;
    bottom: -80px;
    left: -60px;
    line-height: 40px;
    height: 60px;   
    width: 190px;    
}
// Theme Circles End
.btnWizards {
    flex: none !important
}

.ng-wizard-btn-group-extra {
    width: 100% !important;
    justify-content: space-between !important;
}

#button-upload {
    margin-right: auto;
}

.btAnt {
    margin-left: auto !important;
}

.password-input {
    position: relative;
    cursor: pointer;
}

.password-input i {
    position: absolute;
    margin: auto;
    left: auto;
    right: 10px;
    top: 20%;
}

.step-anchor {
    z-index: 9;
}

.margin-right-adjust {
    margin-right: 15px;
}

.filter-btn-pos {
    // background-color: red;
    position: relative !important;
    top: -33px;
}

.interval-container {
    z-index: 55;
}

.filter-top-monitor {
    top: 8px;
}

.swal2-container {
    z-index: 10000 !important;
}

.pos-btns {
    position: relative;
    bottom: 20px;
    z-index: 0;
}

.filter-list-container {
    background-color: rgba($color: #ffffff, $alpha: 0.1);
    border-radius: 15px;
    padding: 10px;
    margin-right: 23px;
}

.card-favoritos {
    background: rgba(255, 255, 255, 0.07);
    border-radius: 15px !important;
    margin: 15px;
    width: 60%;
    padding: 15px;
    label {
        height: 22px;
        color: #fff;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        width: 100%;
        text-align: center;
    }
}

.btn-close-card {
    color: white;
    border: none;
    background: transparent;
    display: flex;
    margin-left: auto;
}

.select-fav {
    margin: 15px;
    display: flex;
    width: 70px;
    height: 40px;
    .btn-select {
        width: 50px;
        height: 29px;
        background: rgba(255, 255, 255, 0.07);
        border-radius: 15px;
        span{
            width: 50px;
            height: 29px;
            padding:0;
            margin:0;
            display: flex;
    justify-content: space-evenly;
    align-items: center;
        }
    }
    // .mat-mini-fab .mat-button-wrapper {
    //     padding: 0;
    //     display: inline-block;
    //     line-height: 24px;
    //     position: relative;
    //     top: -3px;
    // }
}

button.btn-fav {
    height: 25px !important;
    width: 25px !important;
    margin-right: 14px;
    position: relative;
    top: 5px;
    i {
        position: relative;
        left: -7px;
        top: -2px;
    }
}

.max-length-error-msg{
    // position: absolute;top: 50px; font-size: 14px;
}

// .btn-reg{
//     top: 22px;
// }

@media only screen and (min-width: 769px) {
    .btn-reg{
        top: 22px;
    }
}


.qtd-off{
    pointer-events: none;
    opacity: 0.5;
}


.qtd-on{
   
}

.disable-field-css{
  pointer-events: none;
  position: relative;
  opacity: 0.4;
}