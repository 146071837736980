// Body
//
// Settings for the `<body>` element.

$body-bg: #fafafa !default;
$navbar-height: 70px;
$sidebar-bg: #0e0c28;
$type1: "Roboto", sans-serif;
$sidebar-width-lg: 235px;
$sidebar-menu-icon-color: #7072a5;
$sidebar-menu-arrow-color: #7072a5;
$sidebar-menu-font-size: 0.875rem;
$sidebar-icon-font-size: 1.125rem;
$sidebar-menu-active-bg: rgba(49, 45, 78, 0.3);
$sidebar-menu-active-color: #ffffff;
$sidebar-menu-hover-bg:#7072a5;
$sidebar-menu-hover-color:#7072a5;
$sidebar-submenu-padding:10px;
$sidebar-submenu-color:#7072a5;
$sidebar-submenu-item-padding:5px;
$sidebar-submenu-font-size:1.125rem;
$sidebar-submenu-hover-color:#fff;
$sidebar-submenu-active-color:#fff
//$body-color:                 $gray-dark !default;
