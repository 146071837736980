

$blue: #13EBFF;
$green: #6CEFBD;
$red: #FF7080;
$yellow: #FFE28B;
$white: #ffffff;
$text: #8793A2;
$text-light: #C0C4C7;
$border: #D2D6DA;

$upload-box-background: #2C3139;
$upload-box-header-background: #3A414A;
$upload-box-progress-background: #3A4048;




.hero {
   background: transparent
}

.upload-box {
   width: 100%;
   height: auto;
   display: inline-block;
   border-radius: 10px;
 
   .upload-box-header  {
      width: 100%;
      height: 50px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background: $upload-box-header-background;
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      .control-circles {
         padding-left: 15px;
         height: 14px;
   
         .circle {
          display: inline-block;
          margin: 0 4px;
          width: 14px;
          height: 14px;
          border-radius: 50%;
  
          &.is-green {
            background: $green;
          }
  
          &.is-yellow {
            background: $yellow;
          }
            
          &.is-red {
            background: $red;
          }
            
         }
      }
        
         
  
      .header-title {
         h2 {
            font-size: 18px;
            font-weight: 400;
            color: $text;
         }
      }
  
        
          
  
      .gh-icon {
         padding-right: 15px;
  
         i {
          font-size: 24px;
          color: $text;
          margin-right: 5px;
          margin-left: 36px;
          cursor: pointer;
         }
      }
       
          
   }
     
 
   .upload-box-content
 
     .upload-item, .drop-container {
      width: 100%;
     }
       
     .drop-container {
         display: flex;
         align-items: center;
         justify-content: center;

         p {
            font-size: 16px;
            font-weight: 400;
            color: $text;
         }
         

         .upload-button {
            display: inline-block;
            border: none;
            outline: none;
            cursor: pointer;
            font-weight: 600;
            padding: 0 2px;
            margin: 0px;
            width: 100%;
            
            input {
               display: none;
            }
         }
     }
 
     .upload-item {
      display: flex;
      align-items: center;
      padding: 0 30px;

      .upload-item-content {
        width: 100%;

        .filename {
           display: flex;
           align-items: center;
           justify-content: space-between;
 
           .filename-left, .filename-right {
            display: flex;
            align-items: center;
           }
             
           i {
            color: $text;
            opacity: .8;
            font-size: 24px;

            &.ion-close-round {
              &:hover {
                 opacity: 1;
                 cursor: pointer;
              }
            }
           }
            
           span {
            color: $text;
            font-size: 14px;
            font-weight: 400;
            margin-left: 10px;
           }
        }
       
            
        .progress-content {
           display: flex;
           align-items: center;
           margin: 10px 0;
 
           .progress {
            display: block;
            position: relative;
            width: 100%;
            height: 12px;
            border-radius: 10px;
            background: $upload-box-progress-background;

            .bar {
              position: absolute;
              top: 0;
              left: 0;
              height: 12px;
              background: linear-gradient(90deg, rgba(19,235,255,1) 0%, rgba(108,239,189,1) 100%);

              &.is-done {
                 background: $green
              }
            }
           }
        }

        .progress-text-content {
           display: flex;
           align-items: center;
           justify-content: space-between;
           margin: 10px 0 0 0;
 
           .progress-text {
            font-size: 13px;
            font-weight: 600;
            color: $blue;
            transition: color 200ms ease-out;

            &.is-done {
              color: $green;
            }
           }

           .speed-and-eta-text {
            font-size: 13px;
            font-weight: 600;
            color: $text;
           }
        }
      }
     }
     
        
 
   .help-text {
      width: 100%;
      display: inline-block;
      padding: 10px 30px;
      font-size: 12px;
      color: $text;
      box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.6);
  
      span {
       display: block;
       text-align: center;
       margin: 5px 0;
 
       img {
         display: block;
         width: 30px;
         margin: 0 auto 5px auto;
       }
      }
   }
     
       
        
}